import React from 'react';
import erasmusLogo from '../../resources/images/logos/logo-co-fund-300x86.png'
import './index.css'

export default function Footer(){

    return(
        <div className='footer-all'>
        <div className="footer h-[200px]">
            <div className='footer-row flex items-start justify-center w-full h-full gap-72'>
                <div className='footer-menu text-left mt-9'>
                    <h2 className="text-xl font-bold text-[#4ace81]">
                        MENU
                    </h2>
                    <ul className='footer-menu-selection'>
                        <li className="text-sm">
                            <a href='/'> 
                                ○ Home 
                            </a>
                        </li>
                        <li className="text-sm">
                            <a href='/about'> 
                                ○ About 
                            </a>
                        </li>
                        <li className="text-sm">
                            <a href='/partners'> 
                                ○ Partners 
                            </a>
                        </li>
                        {/* <li className="text-sm">
                            <a href='/news'> 
                                ○ News 
                            </a>
                        </li> */}
                        {/* <li className="text-sm">
                            <a href='/contact'> 
                                ○ Contact 
                            </a>
                        </li> */}
                    </ul>
                </div>
                {/* <div className='footer-menu text-left mt-9'>
                    <h2 className="text-xl font-bold text-[#4ace81]">
                        CONTACT
                    </h2>
                    <div className='flex justify-left items-center gap-3'>
                        <a  className="text-sm" href = "mailto: info@sdg4uproject.eu">
                            info@sdg4uproject.eu
                        </a>
                    </div>
                </div> */}
                <div className='footer-menu text-left items-start mt-9'>
                    <h2 className="text-lg font-normal ">
                        PROJECT NUMBER: <b>2022-1-EL01-KA220-HED-000088776</b>
                    </h2>
                    <h2 className="text-md font-normal ">
                        PRIVACY POLICY – COOKIE POLICY
                    </h2>
                    <img  className="mt-2" src={erasmusLogo} alt='erasmus logo' />
                </div>
            </div>
        </div>
        <div className="footer-under h-fit block  w-full text-sm  pt-3">
            <div className="w-full flex items-center justify-center pt-2">
                <p className='pl-60  pr-60  text-center w-auto'>
                    Funded by the European Union. Views and opinions expressed are however those of the author(s) only and do not necessarily reflect those of the European Union or the European Education and Culture Executive Agency (EACEA). Neither the European Union nor EACEA can be held responsible for them.
                </p>
            </div>
            <div className="w-full flex items-center justify-center pt-2">
                <p className='pl-60 pr-60  text-center w-auto'>
                    <b>Disclaimer</b>:
                    Τhe material of the project reflects only the author’s views. The European Commission’s support for the production of this publication does not constitute an endorsement of the contents which reflects the views only of the authors, and the Commission or the Hellenic National Agency cannot be held responsible for any use which may be made of the information contained therein.
                </p>
            </div>
            <br/>
            Copyright © 2024 National and Kapodistrian University of Athens
            <br/>
        </div>
        </div>
    )

}
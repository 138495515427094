import React from 'react';
import { useCookies } from 'react-cookie';


const CookieConsent = () => {
  const [cookies, setCookie] = useCookies(["cookieConsent"]);
  const giveCookieConsent = () => {
    setCookie("cookieConsent", true, { path: "/" });
  };

  return (
    <div className="fixed bottom-0 bg-[#436091] w-full h-16">
        <div className='flex justify-center items-center relative h-full'>
            <p className="text-white">
                We use cookies to enhance your user experience. By using our website,
                you agree to our use of cookies.{" "}
                <br/>
                Learn more about the Personal Data Protection Policy of NKUA:
                <a className="text-blue-300 underline ml-3" href={"https://en.uoa.gr/about_us/personal_data_protection_policy/"}>
                  Learn more
                </a>
            </p>
            <button onClick={giveCookieConsent} className='absolute right-5 bg-slate-50 p-2'>
                Accept
            </button>
            <button onClick={giveCookieConsent} className='absolute right-24 bg-slate-50 p-2'>
                Decline
            </button>
        </div>
    </div>
  );
};

export default CookieConsent;
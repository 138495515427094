import React from 'react';
import './index.css'


export default function PartnerCard({logo, title, logo_width, gap_size}){
    return(
        <a href={'/partners/' + title}>
            <div className='partner-card flex align-center justify-center items-center text-left' style={{gap: `${gap_size}px`}} >
                <img alt='partner logo' style={{ width: `${logo_width}%` }} src={logo} />
                <h3>
                    {title}
                </h3>
            </div>
        </a>
    )
}
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import news from '../../../utils/objects/news.json'
import './index.css'

export default function New(){
    const { title } = useParams();
    const [newsObj, setNews] = useState([])
    const [images, setImages] = useState([])
    const [fetched, setFetched] = useState(false)
    

    useEffect( ()=> {
        console.log(news)
        const filtered = Object.values(news.news).filter(item => item.title === title);
        console.log(filtered)
        setNews(filtered[0])
        setImages(filtered[0].images)
        setFetched(true)
    }, [title])

    return(
        <div>
            { fetched === true &&
            <div>
                <h1 className="partner-title text-[40px] p-12 bg-blue-400 text-white font-bold">
                    {newsObj.title}
                </h1>
                <div className='w-full justify-center items-center'>
                    <div className='w-2/4 m-auto text-left pt-5 pb-5'>
                    <p className='text-[12px] font-bold text-[#33333370]'>{newsObj.date}</p>
                    <h1 className='text-[20px] font-semibold'>Description</h1>
                        <p className='text-justify mt-2'>
                            {newsObj.description}
                        </p>
                        <div class="grid-container-news">
                            { images.map((image) => {
                                console.log(image)
                                return (
                                    <div class="grid-item-news">
                                        <img src={require('../../../resources/images/news/'+ image)} alt='news-display' />
                                    </div>
                                )
                            })}
                            
                        </div>       
                    </div>
                </div>
            </div>
            }
        </div>
    )
}
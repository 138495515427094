import React from 'react';
import './index.css'
export default function DropdownButton() {
    return(
        <div className='flex flex-col dropdownbut'>
            <ul className='flex flex-col gap-4 cursor-pointer'>
                <li> 
                   <a href='https://sdg4u.scanlab.gr' target="_blank" rel="noreferrer"> MAPPING TOOL </a> 
                </li>
            </ul>
            
        </div>
    )
}
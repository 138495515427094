import React from 'react';
import './index.css'
import ParnerCard from './PartnerCard'
import nkua from '../../resources/images/logos/image.png'
import panteio from '../../resources/images/logos/panteio.png'
import alba from '../../resources/images/logos/alba.png'
import murcia from '../../resources/images/logos/UMU-new-logo-300x104.png'
import unimed from '../../resources/images/logos/unimed.png'
import ucc from '../../resources/images/logos/ucc_logo_irish.svg'


export default function Partners(){
    return(
        <div>
            <h1 className="partners-title text-[40px] p-12 bg-[#5FD5F3] text-white font-bold">
                PARTNERS
            </h1>
            <div className='w-full flex justify-center items-center'>
                <div className='grid-partner gap-5'>
                    <ParnerCard logo={nkua} logo_width={65} title={"National and Kapodistrian University of Athens"} gap_size={0}/>
                    <ParnerCard logo={panteio} logo_width={50} title={"Panteion University of Social and Political Sciences"} gap_size={20}/>
                    <ParnerCard logo={alba} logo_width={25} title={"The American College of Greece Research Center"} gap_size={10}/>
                    <ParnerCard logo={murcia} logo_width={40} title={"University of Murcia"} gap_size={40}/>
                    <ParnerCard logo={unimed} logo_width={40} title={"UNIMED – Mediterranean Universities Union"} gap_size={10}/>
                    <ParnerCard logo={ucc} logo_width={30} title={"University College Cork"} gap_size={40}/>
                </div>
            </div>
        </div>
    )
}
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import partners from '../../utils/objects/partners.json'
import './index.css'

export default function Partner(){
    const { name } = useParams();
    const [partner, setPartner] = useState([])
    const [fetched, setFetched] = useState(false)
    

    useEffect(()=> {
        const filtered = Object.values(partners.partners).filter(partner => partner.name === name);
        setPartner(filtered[0])
        setFetched(true)
    }, [name])

    return(
        <div>
            <h1 className="partner-title text-[40px] p-12 bg-[#5FD5F3] text-white font-bold">
                {partner.name}
            </h1>
            <div className='w-full justify-center items-center'>
                <div className='w-2/4 m-auto text-left pt-5 pb-5'>
                    <h1 className='text-[20px] font-semibold'>Description</h1>
                    <p className='text-justify mt-2'>
                        {partner.description}
                    </p>                    
                    <p className='text-justify mt-2'>
                        {partner.sub}
                    </p>
                    <p className='text-justify mt-2'>
                        More information: &nbsp;
                        <a className='text-[blue] underline' href={partner.url}>
                            {partner.url}
                        </a>
                    </p>
                    <div className='team'>
                        <h1 className='text-[20px] font-semibold pt-5'>Team Members</h1>
                        { fetched === true && 
                            partner.teamMembers.map( member => {
                                return (
                                    <div className='member-card p-5 mt-2 bg-[#fafafa]'>
                                        <h2 className='text-lg'>
                                            {member.name}
                                        </h2>
                                        <label className='text-sm'>
                                            {member.role}
                                        </label>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}